body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


input.colourPicker
{

    display: block;
    box-sizing: border-box;
    width: 90px;
    margin: auto;
    padding: 6px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #eee;
    outline: none;
    font: inherit;
    text-transform: uppercase;
    text-align: center;


}

.small.react-colorful
{
  width: 150px;
  height: 150px;

}


.redBlackAnimation
{
  animation: PULSE 0.75s infinite; 

}

@keyframes PULSE{
  0%{color:rgba(255,0,0);}	
	110%{color: black;}
}
.loading {
  font-weight: bold;
  display:inline-block;
  font-family: monospace;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}


